<style>
.hiddenButton {
  display: none !important;
}

.showingButton {
  display: block !important;
}
</style>

<template>
  <Card title="Dados do Representante" v-if="registering">
    <form class="my-9 divide-y">
      <div class="grid grid-cols-12 mb-7 gap-5">
        <Input :loading="loadingFields" label="CPF" name="CPF" mask="###.###.###-##" :value="representativeForm.CPF"
          :validation="formValidation.CPF" @change="changeField" class="col-span-12 sm:col-span-6 lg:col-span-2" />
        <Input :loading="loadingFields" label="Nome Completo" :autofocus="true" name="NomeCompleto"
          :value="representativeForm.NomeCompleto" :validation="formValidation.NomeCompleto" @change="changeField"
          class="col-span-12 sm:col-span-6 lg:col-span-5" />
        <Input :loading="loadingFields" label="Nome Social" name="NomeSocial" :optional="true"
          :value="representativeForm.NomeSocial" :validation="formValidation.NomeSocial" @change="changeField"
          class="col-span-12 sm:col-span-6 lg:col-span-5" />
        <Input :loading="loadingFields" label="RG" name="RG" :value="representativeForm.RG"
          :validation="formValidation.RG" 
          @change="changeField" class="col-span-12 sm:col-span-6 lg:col-span-2" />
        <div class="grid col-span-12 sm:col-span-6 lg:col-span-2">
          <label class="input-label mb-1 w-max">UF de Emissão</label>
          <Multiselect id="UFRG" v-model="representativeForm.UFRG"
            :class="formValidation.UFRG ? 'multiselect-input-invalid' : ''" noResultsText="Nenhum UF encontrado"
            :searchable="true" class="col-span-12 w-full" :options="UFs" placeholder="Selecione" />
          <span class="text-red-500 text-xs w-max">{{ formValidation.UFRG }}&nbsp;</span>
        </div>
        <Input :loading="loadingFields" label="Telefone" name="Telefone" :mask="['(##) ####-####', '(##) #####-####']"
          type="phone" @change="changeField" :value="representativeForm.Telefone" :validation="formValidation.Telefone"
          class="col-span-12 sm:col-span-6 lg:col-span-4" />
        <Input :loading="loadingFields" label="Celular" name="Celular" mask="(##) #####-####" type="phone"
          @change="changeField" :value="representativeForm.Celular" :validation="formValidation.Celular"
          class="col-span-12 sm:col-span-6 lg:col-span-4" />
        <Input :loading="loadingFields" label="Nome da Mãe" name="NomeMae" :value="representativeForm.NomeMae"
          :validation="formValidation.NomeMae" @change="changeField" class="col-span-12 sm:col-span-6 lg:col-span-4" />
        <Input :loading="loadingFields" label="E-mail Principal" name="Email" @change="changeField"
          :value="representativeForm.Email" :validation="formValidation.Email"
          class="col-span-12 sm:col-span-6 lg:col-span-5" />
        <div class="grid col-span-12 sm:col-span-6 lg:col-span-3">
          <label class="input-label mb-1">Data de Nascimento</label>
          <Datepicker v-model="representativeForm.DataNascimento" :typeable="true"
            :class="formValidation.DataNascimento ? 'border-red-500' : ''" inputFormat="dd/MM/y"
            :upperLimit="upperLimit" @change="changeDate" v-maska="'##/##/####'"
            class="col-span-12 w-full custom-input rounded-lg py-2 px-5 DataNascimento" />
          <span class="text-red-500 text-xs DataNascimento">{{ formValidation.DataNascimento }}&nbsp;</span>
        </div>

        <div :class="isSoftwareHouse() ? 'hidden' : 'grid col-span-12'">
          <p>Por gentileza, anexe uma <b>selfie</b> que apareça bem seu rosto, sem adornos como óculos ou bonés.</p>
          <p>Por gentileza, anexe uma imagem da <b>CNH</b> aberta (apresentando suas duas faces) ou o <b>RG</b> (frente
            e verso).</p>
        </div>

        <div :class="isSoftwareHouse() ? 'hidden' : 'grid col-span-12 sm:col-span-6 lg:col-span-3'">
          <FileUpload title="Selfie" fileName="selfie" name="selfie" :estabelecimentoCnpj="estabelecimentoCnpj"
            v-model="representativeForm.selfieInputValue" :validation="isSoftwareHouse() ? '' : formValidation.selfie"
            :value="representativeForm.selfieInputValue" @changeField="changeField" @removeFile="removeFile" />
          <span class="text-red-500 text-xs">{{ formValidation.selfie }}&nbsp;</span>
        </div>

        <div :class="isSoftwareHouse() ? 'hidden' : 'grid col-span-12 sm:col-span-6 lg:col-span-3'">
          <FileUpload title="CNH" fileName="cnh" name="cnh" :estabelecimentoCnpj="estabelecimentoCnpj"
            v-model="representativeForm.cnhInputValue" :validation="isSoftwareHouse() ? '' : formValidation.cnh"
            :value="representativeForm.cnhInputValue" @changeField="changeField" disabled @removeFile="removeFile" />
          <span class="text-red-500 text-xs">{{ formValidation.cnh }}&nbsp;</span>
        </div>

        <div :class="isSoftwareHouse() ? 'hidden' : 'grid col-span-12 sm:col-span-6 lg:col-span-3'">
          <FileUpload title="Frente RG" fileName="rgFront" name="rgFront" :estabelecimentoCnpj="estabelecimentoCnpj"
            v-model="representativeForm.rgFrontInputValue" :validation="isSoftwareHouse() ? '' : formValidation.rgFront"
            :value="representativeForm.rgFrontInputValue" @changeField="changeField" @removeFile="removeFile" />
          <span class="text-red-500 text-xs">{{ formValidation.rgFront }}&nbsp;</span>
        </div>

        <div :class="isSoftwareHouse() ? 'hidden' : 'grid col-span-12 sm:col-span-6 lg:col-span-3'">
          <FileUpload title="Verso RG" fileName="rgBack" name="rgBack" :estabelecimentoCnpj="estabelecimentoCnpj"
            v-model="representativeForm.rgBackInputValue" :validation="isSoftwareHouse() ? '' : formValidation.rgBack"
            :value="representativeForm.rgBackInputValue" @changeField="changeField" @removeFile="removeFile" />
          <span class="text-red-500 text-xs">{{ formValidation.rgBack }}&nbsp;</span>
        </div>
      </div>

      <div class="grid grid-cols-12 mb-7 gap-5">
        <h1 class="inline-block text-3xl col-span-12 mt-5 font-bold text-gray-900 tracking-tight">
          Dados do Endereço
        </h1>
        <Input :loading="loadingFields || loadingAddressFields" label="CEP" name="CEP" :mask="'#####-###'"
          :value="representativeForm.CEP" @change="changeField" :validation="formValidation.CEP"
          class="col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-2" />
        <Input :loading="loadingFields || loadingAddressFields" label="Endereço" name="Endereco"
          :disabled="formFieldsLocked.Endereco" @change="changeField" :value="representativeForm.Endereco"
          :validation="formValidation.Endereco" class="col-span-12 md:col-span-4 lg:col-span-5" />
        <Input :loading="loadingFields || loadingAddressFields" label="Número" name="Numero"
          :value="representativeForm.Numero" @change="changeField" :validation="formValidation.Numero"
          class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2" />
        <Input :loading="loadingFields || loadingAddressFields" label="Complemento" name="Complemento" :optional="true"
          :value="representativeForm.Complemento" @change="changeField" :validation="formValidation.Complemento"
          class="col-span-12 sm:col-span-6 lg:col-span-3" />
        <Input :loading="loadingFields || loadingAddressFields" label="Bairro" name="Bairro"
          :disabled="formFieldsLocked.Bairro" @change="changeField" :value="representativeForm.Bairro"
          :validation="formValidation.Bairro" class="col-span-12 sm:col-span-6 lg:col-span-4" />
        <div class="grid col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
          <label class="input-label mb-1">UF
            <FontAwesomeIcon class="ml-4 text-black self-center animate-spin text-primary" v-if="loadingAddressFields"
              :icon="spinner" />
          </label>
          <Multiselect id="UF" v-model="representativeForm.UF" :disabled="formFieldsLocked.UF" :searchable="true"
            noResultsText="Nenhum UF encontrado" :class="formValidation.UF ? 'multiselect-input-invalid' : ''"
            class="col-span-12 w-full" :options="UFs" @select="getCity" placeholder="Selecione um estado" />
          <span class="text-red-500 text-xs">{{ formValidation.UF }}&nbsp;</span>
        </div>
        <div class="grid col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
          <label class="input-label mb-1">Cidade
            <FontAwesomeIcon class="ml-4 text-black self-center animate-spin text-primary" v-if="loadingAddressFields"
              :icon="spinner" />
          </label>
          <Multiselect id="Cidade" v-model="representativeForm.Cidade" :disabled="formFieldsLocked.Cidade"
            :searchable="true" noOptionsText="Selecione um estado primero" noResultsText="Nenhuma cidade encontrada"
            :class="formValidation.Cidade ? 'multiselect-input-invalid' : ''" class="col-span-12 w-full"
            :options="cities" placeholder="Selecione uma cidade" />
          <span class="text-red-500 text-xs">{{ formValidation.Cidade }}&nbsp;</span>
        </div>
        <div class="grid col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2">
          <label class="input-label mb-1">País
            <FontAwesomeIcon class="ml-4 text-black self-center animate-spin text-primary"
              v-if="loadingFields || loadingAddressFields" :icon="spinner" />
          </label>
          <Multiselect id="Pais" v-model="representativeForm.Pais" :disabled="true" :class="formValidation.Pais ? 'multiselect-input-invalid' : ''"
            class="col-span-12 w-full Pais" :options="['Brasil']" placeholder="Selecione um país" />
          <span class="text-red-500 text-xs">{{ formValidation.Pais }}&nbsp;</span>
        </div>
      </div>
    </form>
    <div class="flex">
      <Button @click="cancel" :icon="backIcon" :class="{ 'custom-buttom-disabled': loading }" :loading="loading" iconPosition="left" label="Cancelar" class="ml-auto" />
      <PrimaryButton @click="add" :icon="newRegister ? plusIcon : saveIcon" iconPosition="right" :loading="loading"
        :class="{ 'custom-buttom-disabled': loading }"
        :label="newRegister ? 'Adicionar' : 'Salvar'" class="ml-5 next" />
    </div>
  </Card>
  <Card title="Representantes Cadastrados" v-else>
    <div class="my-9 grid grid-cols-12 gap-5">
      <div class="bg-gray-200 p-8 shadow-md rounded-lg mb-4 col-span-12 md:col-span-6 lg:col-span-4"
        v-for="representative in representatives" :key="representative.CPF">
        <div class="flex flex-row justify-end mb-5">
          <button @click="toggleRepresentativeAsMain(representative)">
            <FontAwesomeIcon size="xl" class="self-center" :class="representative.isMain ? 'text-yellow-300' : 'text-gray-500'" :icon="starIcon" />
          </button>
        </div>
        <div>
          <p class="font-bold mb-2 truncate">Nome: {{ representative.NomeCompleto }}</p>
          <p class="my-2">CPF: {{ applyMask("CPF", representative.CPF) }}</p>
          <p class="my-2">
            Telefone: {{ representative.Telefone.replace("(", " (").replace(")", ") ") }}
          </p>
          <p class="my-2 truncate">E-mail: {{ representative.Email }}</p>
          <p class="my-2 truncate">Endereço: {{ representative.Endereco }}</p>
        </div>
        <div class="mt-4 dropdown inline-block relative">
          <button class="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
            <span class=""></span>
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </button>
          <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
            <li v-if="representatives.length > 1" @click="remove(representative)" class="">
              <a
                class="min-w-max rounded-t bg-gray-300 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-red-500">
                <FontAwesomeIcon v-if="!loading" class="mr-3 self-center" :icon="trashIcon" />
                Remover
              </a>
            </li>
            <li @click="edit(representative)" class="">
              <a :class="representatives.length > 1 ? 'rounded-b' : 'rounded'"
                class="min-w-max bg-gray-300 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap">
                <FontAwesomeIcon v-if="!loading" class="mr-3 self-center" :icon="editIcon" />
                Editar
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="contents sm:flex">
      <Button @click="newRepresentative" :icon="plusIcon" iconPosition="left" label="Novo Representante"
        class="w-full sm:w-max mb-4 sm:mb-0" />
      <Button @click="back" :icon="backIcon" iconPosition="left" label="Voltar"
        class="sm:ml-auto w-full sm:w-min mb-4 sm:mb-0 hover:bg-gray-300" />
      <PrimaryButton @click="next" :icon="nextIcon" iconPosition="right" :loading="loading" label="Prosseguir"
        class="ml-none sm:ml-5 w-full sm:w-min next" />
    </div>
    <div id="toast-block" :hidden="hidden" @hide="hide" @click.self="hide()">
      <Toast :label="message" @click.self="hide()" :success="toastSuccess" @hide="hide" />
    </div>
  </Card>
</template>

<script>
import Card from "../../../ui/Card.vue";
import Toast from "../../../ui/Toast.vue";
import PrimaryButton from "../../../buttons/PrimaryButton";
import Button from "../../../buttons/Button";
import FileUpload from '../../../input/FileUpload';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faPen,
  faSave,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faTrashAlt,
  faSpinner,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import Input from "../../../input/Input";
import Multiselect from "@vueform/multiselect";
import Datepicker from "vue3-datepicker";
import api from "@/services/api";
import { cep } from "validacao-docs";
import utils from "@/utils";
import { subYears} from "date-fns";

export default {
  name: "RepresentativeData",
  props: ["data", "cnpj_hash", "clientType", "clientId", "preRegister", "estabelecimentoCnpj"],
  components: {
    Card,
    PrimaryButton,
    Multiselect,
    Button,
    Input,
    Datepicker,
    FontAwesomeIcon,
    FileUpload,
    Toast,
  },
  data() {
    return {
      id: 1,
      upperLimit: subYears(Date.now(), 16),
      representatives: [],
      registering: true,
      newRegister: true,
      loading: false,
      spinner: faSpinner,
      message: "",
      hidden: true,
      timeSeen: 20000,
      toastSuccess: true,
      editIcon: faPen,
      plusIcon: faPlus,
      saveIcon: faSave,
      nextIcon: faChevronRight,
      backIcon: faChevronLeft,
      trashIcon: faTrashAlt,
      starIcon: faStar,
      representativeForm: {},
      formValidation: {},
      formFieldsLocked: {},
      UFs: [],
      cities: [],
      date: null,
      loadingAddressFields: false,
      CPFSHOP: false,
      loadingFields: false,
      rgCheck: false,
      representativeDocuments: {
        selfie: null,
        cnh: null,
        rgFront: null,
        rgBack: null,
      },
    };
  },
  async mounted() {
    this.UFs = await utils.getUFs();
    if (!this.representatives.length && this.data && this.data[0]?.uuid) {
      this.representatives = this.data;
    }
    if (!this.representatives.length && this.data?.length > 0 && !this.data[0]?.uuid) {
      this.representatives = this.data.map((representative) => {
        representative.id = this.id++;
        return representative;
      });

      if (this.data.length > 1) {
        this.$swal({
          title: "Aviso!",
          icon: "warning",
          allowOutsideClick: false,
          text: "Verifique os dados dos representantes para informar os arquivos necessários.",
          confirmButtonText: "Ok",
        })
      }
    }
    this.registering = this.representatives.length == 0 || (this.representatives.length == 1 && !this.representatives[0]?.uuid);
    const existsARepresentativeWithouRequiredDocuments = this.clientType === 'company' && 
      this.representatives.find((representative) => !representative.selfie || (!representative.cnh && (!representative.rgFront || !representative.rgBack)));

    if (this.representatives.length == 1 && !this.representatives[0]?.uuid) {
      const representativeData = this.representatives[0];
      representativeData.NomeSocial = representativeData.NomeSocial ?? null;
      representativeData.Complemento = representativeData.Complemento ?? null;
      representativeData.DataNascimento = new Date(representativeData.DataNascimento);
      representativeData.UF = representativeData.UF.toUpperCase();
      representativeData.CEP = utils.applyMask("CEP", representativeData.CEP);
      representativeData.CPF = utils.applyMask("CPF", representativeData.CPF);
      representativeData.Celular = utils.applyMask("Celular", representativeData.Celular);
      this.representativeForm = representativeData
      await this.getCity()
    } else if (existsARepresentativeWithouRequiredDocuments) {
      const representativeData = existsARepresentativeWithouRequiredDocuments;
      representativeData.NomeSocial = representativeData.NomeSocial ?? null;
      representativeData.Complemento = representativeData.Complemento ?? null;
      representativeData.DataNascimento = new Date(representativeData.DataNascimento);
      representativeData.UF = representativeData.UF.toUpperCase();
      representativeData.CEP = utils.applyMask("CEP", representativeData.CEP);
      representativeData.CPF = utils.applyMask("CPF", representativeData.CPF);
      representativeData.Celular = utils.applyMask("Celular", representativeData.Celular);
      this.representativeForm = representativeData
      this.registering = true;
      this.newRegister = false;
      await this.getCity()
      this.$swal({
        title: "Aviso!",
        icon: "warning",
        allowOutsideClick: false,
        text: "Por favor, informe todos os documentos necesśarios do representante.",
        confirmButtonText: "Ok",
      })
      return;
    } else {
      this.formInitialize();
    }
    if (!this.representatives.length) {
      this.representativeForm.isMain = true;
      this.$swal({
        title: "Aviso!",
        icon: "warning",
        allowOutsideClick: false,
        text: "O primeiro representante cadastrado será configurado como o representante principal",
        confirmButtonText: "Ok",
      })
    }
    if (!this.representatives.length && this.preRegister?.email) {
      this.representativeForm.Email = this.preRegister.email
    }
  },
  methods: {
    applyMask(index, value) {
      return utils.applyMask(index, value);
    },

    async changeField(index, value) {
      if (['cnh', 'selfie', 'rgFront', 'rgBack'].includes(index)) {
        if (index == "cnh" && (this.representativeForm.rgFront || this.representativeForm.rgBack)) {
          this.representativeForm[`${index}InputValue`] = undefined;
          this.$swal({
            title: "Erro!",
            icon: "error",
            text: "Não pode ser informado CNH e RG ao mesmo tempo.",
            confirmButtonText: "Ok",
          });
          return;
        }

        if (index == "rgBack" && this.representativeForm.cnh) {
          this.representativeForm[`${index}InputValue`] = undefined;
          this.$swal({
            title: "Erro!",
            icon: "error",
            text: "Não pode ser informado CNH e RG ao mesmo tempo.",
            confirmButtonText: "Ok",
          });
          return;
        }

        if (index == "rgFront" && this.representativeForm.cnh) {
          this.representativeForm[`${index}InputValue`] = undefined;
          this.$swal({
            title: "Erro!",
            icon: "error",
            text: "Não pode ser informado CNH e RG ao mesmo tempo.",
            confirmButtonText: "Ok",
          });
          return;
        }

        this.representativeForm[index] = value.fileName ? value.fileName : value.fileHash;
        this.representativeForm[`${index}InputValue`] = value.fileName;
        this.representativeForm[`${index}Content`] = value.content;
      } else {
        this.representativeForm[index] = value;
        const valueIsValid = await utils.validate(
          index,
          this.representativeForm,
          this.formValidation,
          this.clientType,
          true
        )

        if (valueIsValid) {
          if (index == "CPF") {
            await this.getSHOPData(
              this.representativeForm[index]
                .replaceAll("/", "")
                .replaceAll(".", "")
                .replaceAll("-", "")
            );
          }
          if (index == "CEP") {
            await this.getAddressData();
          }
        }      
      }
    },

    changeDate(event) {
      if (!utils.isValidDate(event.target.value, 16)) {
        this.formValidation.DataNascimento = "Insira uma data válida.";
      } else {
        this.formValidation.DataNascimento = null;
      }
    },

    remove(toBeRemoved) {
      if (!toBeRemoved.uuid) {
        this.representatives = this.representatives.filter((representante) => representante != toBeRemoved);
        return;
      }
      api.delete(`/representative/${toBeRemoved.uuid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }).then(() => {
          this.representatives = this.representatives.filter((representante) => representante != toBeRemoved);
        }).catch( () => {      
          this.$swal({
            title: "Erro!",
            icon: "error",
            allowOutsideClick: true,
            text: "Houve um problema ao remover representante, tente novamente.",
            confirmButtonText: "Ok",
          })
        })        
    },

    toggleRepresentativeAsMain(representative) {
      if (!representative.uuid) {
        representative.isMain = !representative.isMain;
        return;
      }
      this.loading = true;
      api.patch(`/representative/${representative.uuid}`, {
        isMain: !representative.isMain
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(() => {
        representative.isMain = !representative.isMain;
        let message = ''
        if (representative.isMain) {
          this.representatives.forEach((_, index) => {
            if (this.representatives[index].uuid !== representative.uuid) {
              this.representatives[index].isMain = false;
            }
          });
          message = 'Representante principal alterado'
        } else {
          message = 'Representante removido como principal'
        }
        this.loading = false;
        this.registering = false;
        this.hidden = false
        this.toastSuccess = true
        this.message = message
        setTimeout(() => {
          this.hidden = true
        }, this.timeSeen)
      }).catch( () => {      
        this.$swal({
          title: "Erro!",
          icon: "error",
          allowOutsideClick: true,
          text: "Houve um problema ao alterar o representante, tente novamente.",
          confirmButtonText: "Ok",
        })
        this.loading = false;
      })
    },

    async edit(toBeUpdated) {
      this.registering = true;
      this.newRegister = false;

      const representativeData = await toBeUpdated
      this.representativeForm = representativeData.cpf ? utils.formatRepresentativeToForm([representativeData])[0] : representativeData;
      await this.getCity()
    },

    newRepresentative() {
      this.newRegister = true;
      this.registering = true;
    },

    add() {
      this.loading = true;
      this.formValidation = {};

      Object.keys(this.representativeForm).forEach((field) => {
        field = field.replaceAll("Validated", "");

        if (this.clientType === "software-houses") {
          delete this.formValidation['cnh']
          delete this.formValidation['cnhInputValue']
          delete this.formValidation['selfie']
          delete this.formValidation['selfieInputValue']
          delete this.formValidation['rgFront']
          delete this.formValidation['rgFrontInputValue']
          delete this.formValidation['rgBack']
          delete this.formValidation['rgBackInputValue']
        }

        if (
          (!this.representativeForm[field] || this.representativeForm[field] == "") &&
          field != "NomeSocial" &&
          field != "Complemento" &&
          field != "isMain"
        ) {
          this.formValidation[field] = "Este campo é obrigatório.";

          if (this.representativeForm.cnh) {
            delete this.formValidation['rgFront']
            delete this.formValidation['rgFrontContent']
            delete this.formValidation['rgFrontInputValue']
            delete this.formValidation['rgBack']
            delete this.formValidation['rgBackInputValue']
            delete this.formValidation['rgBackContent']
          }
          if (this.representativeForm.rgFront || this.representativeForm.rgBack) {
            delete this.formValidation['cnh']
            delete this.formValidation['cnhInputValue']
            delete this.formValidation['cnhContent']
          }
        }
        utils.validate(
          field,
          this.representativeForm,
          this.formValidation,
          this.clientType
        );
      });

      if (Object.keys(this.formValidation).length > 1) {
        this.loading = false;
        return false;
      }

      if (this.newRegister || !this.representativeForm.uuid) {
        const representativeCreated = this.representativeForm
        api.post(`/representative`, {
          ...utils.formatRepresentativeToDatabase(this.representativeForm)
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }).then(response => {
          const representativeIndex = this.representatives.findIndex((representative) => representative.uuid == representativeCreated.uuid);
          if (representativeIndex !== -1) {
            this.representatives[representativeIndex] = utils.formatRepresentativeToForm([response.data])[0];
          } else {
            this.representatives.push(utils.formatRepresentativeToForm([response.data])[0]);
          }
          this.loading = false;
          this.registering = false;
        }, this).catch( () => {      
          this.$swal({
            title: "Erro!",
            icon: "error",
            allowOutsideClick: true,
            text: "Houve um problema ao registrar representante, tente novamente.",
            confirmButtonText: "Ok",
          })
          this.loading = false;
        })
      } else {        
        const representativeUpdated = this.representativeForm
        api.patch(`/representative/${this.representativeForm.uuid}`, {
          ...utils.formatRepresentativeToDatabase(this.representativeForm),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }).then(response => {
          const representativeIndex = this.representatives.findIndex((representative) => representative.uuid === representativeUpdated.uuid);
          this.representatives[representativeIndex] = utils.formatRepresentativeToForm([response.data])[0];
          this.loading = false;
          this.registering = false;
        }).catch( () => {      
          this.$swal({
            title: "Erro!",
            icon: "error",
            allowOutsideClick: true,
            text: "Houve um problema ao atualizar representante, tente novamente.",
            confirmButtonText: "Ok",
          })
          this.loading = false;
        })
      }

      this.formInitialize();
      this.formValidation = {};
      this.formFieldsLocked = {};
    },

    next() {
      const existsRepresentativeNotCreated = this.representatives.some((representative) => !representative.uuid);
      if (existsRepresentativeNotCreated) {
        this.$swal({
          title: "Aviso!",
          icon: "warning",
          allowOutsideClick: false,
          text: "Por favor, finalize o cadastro dos representantes antes de prosseguir.",
          confirmButtonText: "Ok",
        })
        return;
      }

      const notExistsAMainRepresentative = this.representatives.every((representative) => !representative.isMain);
      if (notExistsAMainRepresentative) {
        this.$swal({
          title: "Aviso!",
          icon: "warning",
          allowOutsideClick: false,
          text: "Por favor, selecione um representante principal.",
          confirmButtonText: "Ok",
        })
        return;
      }

      const existsARepresentativeWithoutDocuments = this.clientType === 'company' && this.representatives.some((representative) => !representative.selfie || (!representative.cnh && (!representative.rgFront || !representative.rgBack)));
      if (existsARepresentativeWithoutDocuments) {
        this.$swal({
          title: "Aviso!",
          icon: "warning",
          allowOutsideClick: false,
          text: "Por favor, informe todos os documentos necessários do(s) representante(s).",
          confirmButtonText: "Ok",
        })
        return;
      }

      this.getLocalStorageDocuments()

      if (this.clientType == "company") {
        this.$emit("next", {
          representantes: this.representatives,
          representativeDocuments: this.representativeDocuments
        });
      } else {
        this.$emit("next", {
          representantes: this.representatives
        });
      }
    },

    back() {
      this.$emit("back", this.representatives);
    },

    cancel() {
      if (this.representatives.length == 0 || !this.representatives[0].uuid) {
        this.$emit("back", this.representatives);
        return
      }
      this.formInitialize();
      this.formValidation = {};
      this.formFieldsLocked = {};
      this.registering = false;
    },

    async getCity(UF) {
      if (UF) {
        this.representativeForm.Cidade = null;
      }
      
      this.cities = await utils.getCitiesByUF(this.representativeForm.UF).catch((response) => {
        this.$emit("registerLastRequest", response);
      });
    },

    async getAddressData() {
      this.loadingAddressFields = true;
      cep
        .getData(this.representativeForm.CEP.replaceAll("-", ""))
        .then((response) => {
          if (response) {
            this.representativeForm.Endereco = response.logradouro;
            this.formFieldsLocked.Endereco = !response.logradouro
              ? false
              : true;
            this.representativeForm.Bairro = response.bairro;
            this.formFieldsLocked.Bairro = !response.bairro ? false : true;
            this.representativeForm.UF = response.uf;
            this.formFieldsLocked.UF = !this.representativeForm.UF
              ? false
              : true;
            this.cities = [response.localidade]
            this.representativeForm.Cidade = response.localidade;
            this.formFieldsLocked.Cidade = !this.representativeForm.Cidade
              ? false
              : true;
            this.representativeForm.Numero = '';
            this.representativeForm.Complemento = '';
          }
          this.loadingAddressFields = false;
        }).catch(() => {
          this.loadingAddressFields = true;
        });
    },

    async getSHOPData(cpf) {
      const this_ = this;
      let updateHash = false;
      this.loadingFields = true;

      return api
        .get(`/pessoas-fisicas/` + cpf)
        .then(async (response) => {
          if (!response.data.hash) {
            this.loadingFields = false;
            return false;
          }
          if (!response.data.disponivel) {
            const interval = setInterval(async function () {
              await api
                .get(`/hashes/` + response.data.hash)
                .then((response) => {
                  if (response.data) {
                    const dados = response.data.data;

                    clearInterval(interval);

                    if (!updateHash) {
                      document
                        .getElementsByClassName("hiddenButton")[0]
                        .classList.add("showingButton");
                      document
                        .getElementsByClassName("cancel-btn")[0]
                        .classList.add("hiddenButton");
                      document
                        .getElementsByClassName("sk-fading-circle")[0]
                        .classList.add("hiddenButton");
                      document
                        .getElementsByClassName("custom-confirm-message")[0]
                        .classList.add("hiddenButton");
                    }
                    this_.representativeForm.NomeCompleto = dados.nome;
                    this_.representativeForm.NomeSocial = dados.nomeSocial;
                    this_.representativeForm.NomeMae = dados.nomeMae;
                    this_.representativeForm.DataNascimento = new Date(
                      dados.dataNascimento
                    );
                    this_.representativeForm.UFRG = dados.ufrg;
                    this_.representativeForm.RG = dados.rg;
                    dados.pessoa.contatos.forEach((contato) => {
                      if (contato.tipo == "email") {
                        this_.representativeForm.Email = contato.valor;
                      }
                      if (contato.tipo == "telefone") {
                        this_.representativeForm.Telefone = `+55${utils.applyMask(
                          "Telefone",
                          contato.valor
                        )}`;
                      }
                      if (contato.tipo == "celular") {
                        this_.representativeForm.Celular = `+55${utils.applyMask(
                          "Celular",
                          contato.valor
                        )}`;
                      }
                    });

                    const endereco = dados.pessoa.enderecos[0];
                    if (endereco) {
                      this_.representativeForm.CEP =
                        endereco.cep.substr(0, 5) +
                        "-" +
                        endereco.cep.substr(5, 3);
                      this_.representativeForm.Numero = String(endereco.numero);
                      this_.representativeForm.Endereco = endereco.logradouro;
                      this_.representativeForm.Complemento =
                        endereco.comlpemento;
                      this_.representativeForm.Bairro = endereco.bairro;
                      this_.representativeForm.UF = endereco.uf;
                      this_.representativeForm.Cidade = endereco.cidade;
                      this.loadingFields = false;
                    }
                    this.loadingFields = false;
                    clearInterval(interval);
                    return;
                  }
                });
            }, 2000);

            if (this.cnpj_hash) {
              await api.get(`/hashes/` + this.cnpj_hash).then((response) => {
                if (response.data) {
                  updateHash = true;
                }
              });

              if (updateHash) {
                await api.put("/hashes/" + response.data.hash);
              }
            } else {
              return this.$swal({
                title: "Este CPF já consta em nossa base!",
                html: `
    Foi enviado um e-mail para ${response.data.email} solicitando a confirmação para continuar o cadastro. Por favor, confirme o e-mail para continuar aqui. <br/><br/><b>Mantenha esta página aberta para continuar o processo de cadastro.</b><br/>
        <div class="loading-spinner">
            <div class="sk-fading-circle">
                <div class="sk-circle1 sk-circle"></div>
                <div class="sk-circle2 sk-circle"></div>
                <div class="sk-circle3 sk-circle"></div>
                <div class="sk-circle4 sk-circle"></div>
                <div class="sk-circle5 sk-circle"></div>
                <div class="sk-circle6 sk-circle"></div>
                <div class="sk-circle7 sk-circle"></div>
                <div class="sk-circle8 sk-circle"></div>
                <div class="sk-circle9 sk-circle"></div>
                <div class="sk-circle10 sk-circle"></div>
                <div class="sk-circle11 sk-circle"></div>
                <div class="sk-circle12 sk-circle"></div>
            </div>
            <div class="custom-confirm-message">Aguardando a confirmação do uso dos dados...</div>
        </div>
    `,
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                icon: 'warning',
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                customClass: {
                  confirmButton: "hiddenButton",
                  cancelButton: "cancel-btn",
                  content: "custom-swal-content"
                },
              }),
                this_.loadingFields = false;
            }
          }
        })
        .catch((response) => {
          this.loadingFields = false;
          this.$emit("registerLastRequest", response);
        });
    },

    async removeFile(fileName) {
      const oldFileData = this.representativeForm[fileName];
      const oldFileInputValue = this.representativeForm[`${fileName}InputValue`];
      const oldFileContent = this.representativeForm[`${fileName}Content`];
      try {
        this.representativeForm[fileName] = ''
        this.representativeForm[`${fileName}InputValue`] = ''
        this.representativeForm[`${fileName}Content`] = ''
        if (this.representativeForm.uuid) {
          await api.delete(`/representative/${this.representativeForm.uuid}/documents/${fileName}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
        }
      } catch(error) {
        this.representativeForm[fileName] = oldFileData
        this.representativeForm[`${fileName}InputValue`] = oldFileInputValue
        this.representativeForm[`${fileName}Content`] = oldFileContent
        this.$swal({
          title: "Erro!",
          icon: "error",
          allowOutsideClick: true,
          text: "Houve um problema ao remover o arquivo, tente novamente.",
          confirmButtonText: "Ok",
        })
      }
    },

    sort(array, index) {
      return array.sort(function (first, second) {
        if (first[index] > second[index]) {
          return 1;
        }
        if (first[index] < second[index]) {
          return -1;
        }
        return 0;
      });
    },

    formInitialize() {
      this.representativeForm = {
        id: this.id++,
        NomeSocial: "",
        NomeMae: "",
        NomeCompleto: "",
        Email: "",
        Celular: "",
        Telefone: "",
        DataNascimento: null,
        CPF: "",
        RG: "",
        CEP: "",
        Numero: "",
        Bairro: "",
        Complemento: "",
        Endereco: "",
        UF: null,
        UFRG: null,
        Cidade: null,
        Pais: "Brasil",
        selfie: null,
        cnh: null,
        rgFront: null,
        rgBack: '',
      };
    },

    isSoftwareHouse() {
      return this.clientType == "software-houses";
    },

    getLocalStorageDocuments() {
      this.representativeDocuments.cnh = localStorage.getItem('cnh')
      this.representativeDocuments.rgFront = localStorage.getItem('rgFront')
      this.representativeDocuments.rgBack = localStorage.getItem('rgBack')
      this.representativeDocuments.selfie = localStorage.getItem('selfie')
    },

    hide () {
      this.hidden = true
    },
  },
};
</script>
